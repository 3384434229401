import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SeoMeta"
import HomeContent from "../components/pages/HomeContent"

const IndexPage = props => {
  const { siteMetadata } = props.data.site
  const seoImage = props.data.contentfulHome.featuredImage.fixed

  return (
    <>
      <SEO {...siteMetadata} image={seoImage} title="Photographer" />
      <HomeContent grey time={1000} {...props.data} />
    </>
  )
}

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulImagesSection {
      sets {
        title
        contentfulid
        featuredImage {
          id
          title
          description
          fixed(width: 1200, height: 630) {
            height
            src
            width
          }
          fluid(maxWidth: 1200, maxHeight: 500) {
            srcWebp
            srcSetWebp
            src
            sizes
            srcSet
            aspectRatio
          }
        }
      }
    }
    contentfulProjectSection {
      sets {
        title
        contentfulid
        featuredImage {
          id
          title
          description
          fixed(width: 600) {
            height
            src
            width
          }
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          fluid(maxWidth: 1200) {
            srcWebp
            srcSetWebp
            src
            sizes
            srcSet
            aspectRatio
          }
        }
      }
    }
    contentfulHome {
      slideDuration
      logos {
        id
        image {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      slides {
        fillScreen
        mask
        lightText
        image {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          fluid(maxWidth: 1200) {
            srcWebp
            srcSetWebp
            src
            sizes
            srcSet
            aspectRatio
          }
        }
        link {
          contentfulid
          images {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 1200) {
              srcWebp
              srcSetWebp
              src
              sizes
              srcSet
              aspectRatio
            }
          }
        }
      }
      featuredImage {
        fixed(width: 1200, height: 630) {
          src
          height
          width
        }
      }
      images {
        id
        title
        description
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
        fluid(maxWidth: 1200) {
          srcWebp
          srcSetWebp
          src
          sizes
          srcSet
          aspectRatio
        }
      }
    }
  }
`

export default IndexPage
